<template>
  <div class="myContract-page">
    <div class="myContract-header">
      <van-tabs @click="onClick" color="#1677FF" title-active-color="#1677FF" v-model="isSign">
        <van-tab title="已签合同" name="signed"></van-tab>
        <van-tab title="未签合同" name="unsign"></van-tab>
      </van-tabs>
    </div>
    <!-- <div>
      <van-dropdown-menu active-color="#1677FF">
        <van-dropdown-item :title="billYear" ref="yearTwo">
          <drop-down-selection
            :currentValue="currentIndex"
            :dataValArr="yearList"
            @getSelectValue="getSelectValueInfo"
          />
        </van-dropdown-item>
      </van-dropdown-menu>
    </div> -->
    <van-pull-refresh
      v-model="refreshing"
      @refresh="onRefresh"
      :disabled="true"
      v-if="dataInfoFlag"
    >
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="selectFeedbackHandling"
      >
        <div class="myFeedBack-content">
          <ul>
            <li v-for="(item, index) in dataInfo" :key="index">
              <div class="first-li">
                <span
                  >合同名称：{{ item.contractName }}
                  <van-tag type="success" v-if="item.isSign === '1'" class="tag">成功</van-tag
                  ><van-tag type="danger" v-if="item.isSign === '2'" class="tag"
                    >失败</van-tag
                  ></span
                >
              </div>
              <div class="last-li" v-if="item.isSign === '0'">
                <span>{{ item.createdTime }}创建</span>
                <van-button type="default" size="small" @click="toSignContract(item)">
                  签署合同
                </van-button>
              </div>
              <div class="last-li" v-else>
                <span>{{ item.contractTime }}签署</span>
                <van-button type="default" size="small" @click="toShowDetail(item)">
                  查看详情
                </van-button>
              </div>
            </li>
          </ul>
        </div>
      </van-list>
    </van-pull-refresh>

    <no-data v-else />
  </div>
</template>

<script>
import store from '@/store';
import { mapState } from 'vuex';
import NoData from '@/components/noData';
import DropDownSelection from '@/components/dropDownSelection';
import { formatTime } from '@/utils/tools';
import { detailsRoute } from '@/utils/businessRoute';

import { selectBusCode, selectFeedbackHandling } from '../../api/home';
import { getAllContract, signRedirect } from '@/api/contract';

export default {
  name: 'MyFeedBack',
  components: {
    NoData,
    DropDownSelection,
  },
  data() {
    return {
      titleValue: '反馈类型筛选',
      tabKeyValueList: [],
      tabAllList: [], // tab全部code结合
      typeAllList: [], // type...
      dataInfo: [],
      dataInfoFlag: true,
      currentIndex: 0,
      size: 5,
      currentPage: 1,
      dataTotal: 0,
      refreshing: false,
      loading: false,
      finished: false,
      selectedFlag: true,
      billYear: '年份',
      currentIndex: 0,
      yearList: [],
      isSign: 'signed',
      year: new Date().getFullYear(),
    };
  },
  computed: {
    ...mapState('dict', {
      dictory: (state) => state.dictory,
    }),
  },
  mounted() {
    this.isSign = this.$route.query.isSign;
    // this.getYear();
  },
  methods: {
    // 选择年份 - 获取年份(12个月份)
    getYear() {
      const date = new Date();
      const year = date.getFullYear();
      for (let i = year - 11; i <= year; i++) {
        this.yearList.push({
          name: i + '年',
        });
      }
      this.yearList.reverse();
    },
    getSelectValueInfo(value, index) {
      this.billYear = value;
      this.currentIndex = index;
      this.$forceUpdate();

      this.currentPage = 1;
      this.dataInfo = [];
      this.selectFeedbackHandling();
      this.$refs.yearTwo.toggle();
    },
    // 获取页面数据
    async selectFeedbackHandling() {
      // let billYear =
      //   this.billYear === '年份'
      //     ? new Date().getFullYear() + ''
      //     : this.billYear.substring(0, this.billYear.lastIndexOf('年'));
      let arg = {
        signType: this.isSign,
      };
      const { status, resultData } = await getAllContract(arg);
      if (status === 'complete') {
        if (resultData.length > 0) {
          this.dataInfoFlag = true;
          // 分页
          this.dataTotal = resultData.length;
          this.dataInfo = this.dataInfo.concat(resultData);
          this.currentPage++;
          // 没有更多数据
          if (this.dataInfo.length >= Number(this.dataTotal)) {
            this.finished = true;
          } else {
            this.finished = false;
          }
        } else {
          this.dataInfo = [];
          this.dataInfoFlag = false;
          this.finished = true;
        }
      } else {
        this.finished = true;
      }
      this.loading = false;
    },
    // tab切换
    onClick(name, title) {
      this.isSign = name;
      this.currentPage = 1;
      this.dataInfo = [];
      this.dataTotal = 0;
      // this.finished = false;
      this.selectFeedbackHandling();
    },
    // 下拉刷新
    onRefresh() {
      this.currentPage = 1;
      this.dataInfo = [];
      this.typeAllList = [];
      this.tabAllList = [];
      this.finished = false;
      this.selectFeedbackHandling();
      this.loading = false;
    },
    toSignContract(item) {
      signRedirect(item.businessFlowId).then((res) => {
        if (res.status === 'complete') {
          if (res.resultData === 'verify') {
            this.$router.push({
              path: '/CertifiedContract',
              query: { businessFlowId: item.businessFlowId },
            });
          } else if (res.resultData === 'default') {
            this.$router.push({ path: '/' });
          } else {
            let link = document.createElement('a');
            link.style.display = 'none';
            link.href = item.contractUrl;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      });
    },
    toShowDetail(item) {
      let link = document.createElement('a');
      link.style.display = 'none';
      link.href = item.contractUrl;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="less" scoped>
.myContract-page {
  .myContract-header {
    /deep/.van-tab {
      padding: 0 10px;
    }
  }
  .myContact-select {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: rgba(0, 0, 0, 0.65);
    }
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent rgba(0, 0, 0, 0.65) rgba(0, 0, 0, 0.65);
    }
    // /deep/.van-dropdown-menu__title::after {
    //   border-color: transparent transparent #555555 #555555;
    // }
    /deep/.van-dropdown-menu__item {
      margin-right: 10px;
    }
  }
  // 选中的样式
  .myContact-selected {
    display: flex;
    justify-content: space-between;
    padding: 16px 16px 10px 16px;
    font-size: 14px;
    background: #ffffff;
    /deep/.van-dropdown-menu__bar {
      height: auto;
      background: transparent;
      box-shadow: none;
    }
    /deep/.van-dropdown-menu__title {
      width: 110px;
      color: #1677ff;
    }
    /deep/.van-dropdown-menu__title::after {
      border-color: transparent transparent #1677ff #1677ff;
    }
  }
  .myFeedBack-content {
    ul {
      margin-left: 16px;
      margin-right: 16px;
      margin-top: 10px;
      li {
        margin-bottom: 16px;
        background: #ffffff;
        border-radius: 8px;
        padding-left: 16px;
        padding-right: 16px;
        .first-li {
          padding-top: 10px;
          margin-left: 16px;
          margin-right: 16px;
          margin-bottom: 14px;
          display: flex;
          justify-content: space-between;
          span {
            font-family: PingFangSC-Medium;
            font-size: 16px;
            color: rgba(0, 0, 0, 0.85);
            letter-spacing: 0;
            line-height: 20px;
            font-weight: 600;
          }

          .tag {
            color: #fff;
            font-family: PingFangSC-Regular;
            font-size: 12px;
            border-radius: 6px;
          }
        }
        .last-li {
          display: flex;
          justify-content: space-between;
          margin-left: 16px;
          margin-right: 16px;
          padding-bottom: 11px;
          span {
            font-size: 12px;
            line-height: 32px;
            font-family: PingFangSC-Regular;
            color: rgba(0, 0, 0, 0.45);
            letter-spacing: 0;
          }
          .van-button {
            border-radius: 8px;
            padding-left: 8px;
            padding-right: 8px;
            span {
              font-family: PingFangSC-Regular;
              font-size: 12px;
              color: #4285f4;
              letter-spacing: 0;
              text-align: center;
              line-height: 24px;
            }
          }
          .van-button--default {
            border: 1px solid #4285f4;
          }
        }
      }
    }
  }
}
</style>
